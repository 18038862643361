import { Navigate } from "react-router-dom";

export const PrivatePath = ({ children }) => {
  const isLoggedIn = sessionStorage.getItem("isLoggedin");
  if (!isLoggedIn) {
    const param = btoa("err=Session Expired");
    return <Navigate to={`/?${param}`} replace />;
  }
  return children;
};
